<template>
  <div class="errorP">

    <div class="errorP-inner">
      <h1><img src="@/assets/img/sub/notice.png" alt="notice"></h1>
      <p>토큰이 유효하지 않습니다.</p>
      <div>
        <router-link to="/" class="btn color-1 btn-large w-200 btn-round100 mr-10">꼬랩 사이트로 이동</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>
